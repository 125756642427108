//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      hasCookie: false,
    }
  },
  mounted() {
    this.checkCookie()
  },
  computed: {
    isMobile() {
      if (process.client) {
        return window.innerWidth <= 440;
      }
      return false;
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
      openLink() {
        window.open('https://t.me/RabotutJobs/625?utm_source=tg&utm_medium=event220824', '_blank', 'noopener,noreferrer');
      },
    checkCookie() {
      const cookieAccepted = localStorage.getItem('accepted_cookie_policy');
      this.hasCookie = !!cookieAccepted;
    },
  },
};
