//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { version } from '../package.json'
import BaseNav from '~/components/UI/BaseNav.vue'
// import { VACANCY_COUNT } from '~/services/api/graphql/query/vacancyCount'

export default {
  name: 'CommonFooter',
  components: {
    BaseNav,
  },
  props: {
    links: {
      type: Array || Function,
      default: [
        { title: 'Вакансии', link: '/vacancies' },
        { title: 'Карьера', link: '/career' },
        { title: 'Обучение', link: '/training' },
        { title: 'Акции', link: '/promo' },
        { title: 'О нас', link: '/company' },
        { title: 'Помощь', link: '/help' },
        { title: 'Контакты', link: '/contacts' },
        { title: 'Карта сайта', link: '/sitemap' },
      ],
    },
  },
  data() {
    return {
      icons: [
        { link: 'https://ok.ru/group/58725888819340', name: 'odk' },
        { link: 'https://vk.com/rabotutru', name: 'vk' },
        { link: 'https://t.me/RabotutJobs', name: 'telegram' },
      ],
      appVersion: version,
      count: 0,
    }
  },
  computed: {
    ...mapState('user', ['phone']),
    ...mapState('dictionary', ['vacancyCount']),
    ...mapState('core', ['city']),
    hrefPhone() {
      return this.phone.replace(/[^0-9]/g, '')
    },
    computedCity() {
      return this.city?.city || ''
    },
  },
}
