import { render, staticRenderFns } from "./main.layout.vue?vue&type=template&id=79b1f874&scoped=true&"
import script from "./main.layout.vue?vue&type=script&lang=js&"
export * from "./main.layout.vue?vue&type=script&lang=js&"
import style0 from "./main.layout.vue?vue&type=style&index=0&id=79b1f874&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b1f874",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/builds/rabotut/ui-web/project/components/Header.vue').default,Footer: require('/builds/rabotut/ui-web/project/components/Footer.vue').default,ModalGiveaway: require('/builds/rabotut/ui-web/project/components/ModalGiveaway.vue').default})
